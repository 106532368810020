import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29')
];

export const server_loads = [0];

export const dictionary = {
		"/(authenticated)": [6,[2]],
		"/(unauthenticated)/about": [18,[5]],
		"/(unauthenticated)/acceptInvitation": [19,[5]],
		"/(authenticated)/admin": [7,[2]],
		"/(authenticated)/authorize": [8,[3]],
		"/email/tester": [29],
		"/(unauthenticated)/forgotPassword": [20,[5]],
		"/(unauthenticated)/forgotPassword/emailSent": [21,[5]],
		"/(authenticated)/home": [9,[2]],
		"/(unauthenticated)/login": [~22,[5]],
		"/(authenticated)/org/create": [11,[2]],
		"/(authenticated)/org/list": [12,[2]],
		"/(authenticated)/org/[org_id]": [10,[2]],
		"/(authenticated)/project/create": [15,[2]],
		"/(authenticated)/project/[project_code]": [13,[2]],
		"/(authenticated)/project/[project_code]/viewer": [14,[4]],
		"/(unauthenticated)/register": [23,[5]],
		"/(authenticated)/resetPassword": [16,[2]],
		"/(unauthenticated)/sandbox": [24,[5]],
		"/(unauthenticated)/sandbox/i18n/late": [26,[5]],
		"/(unauthenticated)/sandbox/i18n/ssr-only": [27,[5]],
		"/(unauthenticated)/sandbox/[status_code]": [25,[5]],
		"/(authenticated)/user": [17,[2]],
		"/[...catch_all]": [~28]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';